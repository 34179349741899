/* eslint-disable jsx-a11y/no-noninteractive-element-interactions -- Needed to disable interactivity from child. */
import clsx from "clsx"
import React, { useState } from "react"
import { Page } from "../components/layout"
import { type FAQ, faqData } from "../data/faqData"
import { Card } from "../components/card/Card"
import "./faq.scss"

const FAQCard: React.FC<{ faq: FAQ }> = ({ faq }) => {
  const [open, setOpen] = useState(false)
  const toggleCard = () => {
    setOpen(!open)
  }
  return (
    <div
      onClick={toggleCard}
      role="button"
      tabIndex={0}
      onKeyUp={(event) => {
        if (event.key == `Enter`) {
          toggleCard()
        }
      }}
      className={clsx(
        `card-faq card-wrapper col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3`
      )}
    >
      <Card trim="faqpurple">
        <div className="faq-question w-full text-left">
          <h3>{faq.question}</h3>
          <div className={`faq-toggle ${open && `open`}`} />
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation()
          }}
          onKeyUp={() => {}}
          role="main"
          className={clsx(`cursor-default prose mt-[24px]`, {
            hidden: !open,
          })}
        >
          {typeof faq.answer === `string` ? <p>{faq.answer}</p> : faq.answer}
        </div>
      </Card>
    </div>
  )
}

export const FAQPage = () => (
  <Page
    header={
      <>
        Frequently Asked
        <br />
        Questions
      </>
    }
    title="Frequently Asked Questions (FAQs)"
    keywords="xrpl accelerator faq, xrpl accelerator questions, xrpl accelerator application, xrpl accelerator help"
    description="Want to learn more about the XRPL accelerator program? Check out these helpful XRPL accelerator FAQs to get learn about applying, deadlines, and more."
    slug="faq"
  >
    <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-y-[40px]">
      {faqData.map((faq) => (
        <FAQCard faq={faq} key={faq.question} />
      ))}
    </div>
  </Page>
)

export default FAQPage
