import React from "react"
import { UniversalLink } from "../components/UniversalLink"
import { scrollToCTA } from "../pages"

export interface FAQ {
  question: string
  answer: string | JSX.Element
}

export const faqData: FAQ[] = [
  {
    question: `Who can apply for the XRPL Accelerator Program?`,
    answer: (
      <>
        Submitting an application requires you to be at least 18 years of age
        and not a citizen of, or located in, a country or territory on an {` `}
        <UniversalLink to="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information">
          OFAC Sanctions List
        </UniversalLink>
        . To benefit meaningfully from the program, you should at least have a
        Minimum Viable Product (MVP) that can capture sufficient market demand.
        Ideally, you’re part of a technical founding team, have developed a
        strong product, and demonstrate entrepreneurial drive.
      </>
    ),
  },
  {
    question: ` Where can I learn more about the XRP Ledger?
    `,
    answer: (
      <>
        For more information and a broad range of resources, incl. reference
        docs, visit {` `}
        <UniversalLink to="https://xrpl.org">XRPL.org</UniversalLink>. The XRP
        Ledger is always online and entirely public. Anyone can access it
        directly from a web browser; find a number of source code examples and
        tutorials{` `}
        <UniversalLink to="  https://xrpl.org/xrpl-quickstart.html">
          here
        </UniversalLink>
        . We also recommend the free {` `}
        <UniversalLink to="https://xrpl.org/get-started.html">
          Intro to the XRPL
        </UniversalLink>
        {` `}and {` `}
        <UniversalLink to="https://learn.xrpl.org/course/code-with-the-xrpl/">
          Code with the XRPL
        </UniversalLink>
        {` `}courses. A great way to engage with the XRPL community is joining
        the
        {` `}
        <UniversalLink to="https://discord.com/invite/CVG6Q2S3R8">
          Discord channel
        </UniversalLink>
        . There are many ways to interact with the XRP Ledger, incl. client
        libraries (SDKs) in JavaScript, Python, and Java. There are also APIs
        available over HTTP (JSON-RPC) and WebSockets. Previous XRPL experience
        is highly encouraged when joining the accelerator. An understanding of
        the ledger and the technical ability to execute your project by the core
        team are critical elements in our assessment.
      </>
    ),
  },
  {
    question: `Do I have to have a company in order to receive funding?`,
    answer: (
      <>
        Not necessarily. We will, on a case-by-case basis, consider applications
        submitted by small teams pre-incorporation. Potential venture funding
        after concluding the program, however, is only accessible to
        incorporated entities and not individuals.
      </>
    ),
  },
  {
    question: `Is venture funding/funding for equity part of the XRPL Accelerator program?
    `,
    answer: (
      <>
        Not by default. We understand each startup has a unique growth path,
        need for funding, and commercial potential. When concluding the program,
        early-stage investors, incl. Ripple, assess each startup individually
        for a fair valuation based on funding requirements. Finishing the
        program does not guarantee an investment offer. If an investment offer
        is extended to your team, it is up to you whether or not to accept the
        term sheet/conditions; joining the program entails in no way any
        commitment to give away equity. Startups can join the program
        irrespective of when/how/if they plan to raise funds. Your fundraising
        objectives play no role in evaluating your application; we evaluate
        based on product, team, traction, and scalability.
      </>
    ),
  },
  {
    question: `Based on what criteria are applications evaluated?`,
    answer: (
      <>
        We accept teams that are building on XRPL and have at least an MVP or a
        roadmap to launch one. Further, evaluations will be based on whether
        your team has strong technical foundations and is devoted to the
        project. Your team should have a strong hypothesis on its market
        validation and there should be sufficient market opportunity. In
        evaluating your application, we take a balanced, holistic approach, and
        blend quantitative with qualitative factors. If you’re unsure about the
        readiness of your startup for the accelerator, please feel free to
        {` `}
        <UniversalLink to="mailto:info@xrplaccelerator.org">
          get in touch
        </UniversalLink>
        , or submit your application regardless as you may always reapply.
      </>
    ),
  },
  {
    question: `Can I reapply if a previous application was unsuccessful?`,
    answer: `Yes, we strongly encourage you to do so. If you reapply, please emphasize the progress you accomplished since having submitted your previous, unsuccessful application.`,
  },
  {
    question: `What information will I need to provide in my application?`,
    answer: (
      <>
        Submitting an application should take no more than 15 minutes. We ask
        you to provide details about your product, traction, and organization.
        You can upload supporting materials, preferably a pitch deck. Please
        submit your GitHub repo and ensure it’s accessible to us for faster
        reviewing.
      </>
    ),
  },
  {
    question: `Can I apply if my team has previously been part of other XRPL funding programs?`,
    answer: `Yes, we strongly encourage recipients of other XRPL funding programs to apply for the Accelerator program. Whether your team benefited from other XRPL funding programs before will have no impact on how we evaluate your application; all we look at is your product, team, traction, and scalability.`,
  },
  {
    question: `What is the average processing time for applications?`,
    answer: `We aim to conclude pre-screenings on a rolling basis and within 1 week of submission.`,
  },
  {
    question: ` Can I apply for a cohort outside of the region my team is based in?`,
    answer: ` Yes. We do, however, strongly encourage you to apply for a cohort that best suits your business interests and team setup. Mentor availability and curriculum delivery align with the timezone of the program location. Part of the program content is tailored to the respective region. Each cohort features an on-site demo week at the end of the program; we strongly encourage each participating team to make use of this opportunity. We aim to align the on-site demo week with a global blockchain conference in the respective location.  
    `,
  },
  {
    question: `Is being on-site a requirement for participating in the program?`,
    answer: `No, the default format of the program is hybrid and predominantly virtual. You are welcome to join us on-site at all times, but if you’re unable to travel, you can still access all benefits the program offers. Each cohort features an on-site demo week at the end of the program; we strongly encourage each participating team to make use of this opportunity. We aim to align the on-site demo week with a global blockchain conference in the respective location.`,
  },
  {
    question: `Who runs the operations of the XRPL Accelerator?`,
    answer: `The XRPL Accelerator program is offered by Ripple Labs Inc. (“Ripple”) and its subsidiaries and affiliated companies.`,
  },
  {
    question: `Does participating in the XRPL Accelerator mean my organization becomes directly affiliated with Ripple?`,
    answer: ` No, solely participating in the program does not by default make you an affiliate of Ripple and its subsidiaries and affiliated companies. The terms for participating do not alter the terms or conditions of any other agreement you may have with Ripple.`,
  },
  {
    question: `When will my team receive the $50,000 program award?`,
    answer: `50% of the award will become accessible to you at program start, the remaining 50% with successfully completing a mid-program milestone.`,
  },
  {
    question: `Are there any conditions attached to the $50,000 program award?`,
    answer: ` No, the award can essentially be seen as a grant and is to support your team throughout participating in the program. The program award requires no equity or commitments from you in return. Each recipient is responsible for determining possible tax implications of receiving such grants, which depend on your respective jurisdiction. A small, capped portion of no more than $10,000 is earmarked for program-related expenses and services your team will benefit from, such as legal advisory (favorable terms & rates) or mentoring.`,
  },
  {
    question: `What time commitment should my team expect from participating in the program?`,
    answer: `This primarily depends on your individual goals and what your team aims to get out of the program. Pre-scheduled, fixed program elements will not exceed 3h per week, but you are strongly encouraged to engage with mentors, experts, and other program content as much as possible.`,
  },
  {
    question: `Where can I find the terms & conditions for the XRPL Accelerator program?`,
    answer: (
      <>
        Terms & conditions can be viewed {` `}
        <UniversalLink to="/terms">here</UniversalLink>.
      </>
    ),
  },
]
