import clsx from "clsx"
import React from "react"
import "../../assets/card.scss"

export type CardTrimColor =
  | `orange`
  | `blue`
  | `magenta`
  | `purple`
  | `yellow`
  | `green`
  | `faqpurple`
export type CardProps = React.PropsWithChildren<{
  className?: string
  title?: string
  trim?: boolean | CardTrimColor
}>

export const Card: React.FC<CardProps> = ({
  children,
  className,
  title,
  trim = true,
}) => (
  <div className={clsx(`card rounded overflow-hidden shadow-lg`, className)}>
    {trim && <div className={getTrimClass(trim)} />}
    {title && <h3>{title}</h3>}
    {children}
  </div>
)

function getTrimClass(trim?: boolean | CardTrimColor) {
  return clsx(`trim`, trim !== true && `trim-${trim}`)
}
